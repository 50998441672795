.dashed-add {
    position: relative;
    width: 100%;
    .dashed-line {
        display: block;
        width: 100%;
        border-bottom: dashed 2px #999;
        height: 18px;
        position: absolute;
        z-index: 0;
        top: 0;
    }
    button {
        margin: auto;
        height: 36px;
    }
}
